import React, {Component} from 'react'
// import logo from './logo.svg'
import './App.css'

import Navbar from './modules/Navbar'
import Footer from './modules/Footer'
import LanguageTag from './modules/LanguageTag'

class App extends Component {
	render() {
		return (
			<div className="App">
				{/*<Navbar />*/}

				<h1 style={{color: 'white', marginTop: '3em', marginBottom: '3em', fontSize: '3em'}}>
					Sondre Gjellestad
				</h1>

				{/*
					<LanguageTag language="swift">Swift</LanguageTag>
					<LanguageTag language="node">Node</LanguageTag>
				*/}
				<Footer />
			</div>
		)
	}
}

export default App;
