import React, {Component} from 'react'

import './Footer.css'

import FeatherIcon from 'feather-icons-react'

class Footer extends Component {

	render() {
		return (
			<footer style={{margin: 50, color: '#FFF', textAlign: 'center'}}>
				<div className="SocialLinks">
					<table>
						<tbody>
							<tr>
								<td>
									<a className="SocialLink GitHubIcon" href="https://github.com/sondregj">
										<FeatherIcon className="SocialIcon" size="30px" icon="github" />
									</a>
								</td>
								
								<td>
									<a className="SocialLink TwitterIcon" href="https://twitter.com/sondregj">
										<FeatherIcon className="SocialIcon" size="30px" icon="twitter" />
									</a>
								</td>
								
								<td>
									<a className="SocialLink FacebookIcon" href="https://facebook.com/sondregj">
										<FeatherIcon className="SocialIcon" size="30px" icon="facebook" />
									</a>
								</td>
								
								<td>
									<a className="SocialLink CodepenIcon" href="https://codepen.com/sondregj">
										<FeatherIcon className="SocialIcon" size="30px" icon="codepen" />
									</a>
								</td>
						
								<td>
									<a className="SocialLink WebPageIcon" href="https://sondregjellestad.space">
										<FeatherIcon className="SocialIcon" size="30px" icon="globe" />
									</a>
								</td>
								
							</tr>
						</tbody>
					</table>
				</div>
				<span className="Copyright">Sondre Gjellestad | 2018</span>
			</footer>
		)
	}
}

export default Footer
