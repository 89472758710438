import React, {Component} from 'react'

import './LanguageTag.css'

const colors = {
	'swift': '#FFB24A',
	'node': '#44883E'
}

class LanguageTag extends Component {
	render() {
		return (
			<span className="LanguageTag" style={{
				color: colors[this.props.language],
				borderColor: colors[this.props.language]
			}}>
				{this.props.children}
			</span>
		)
	}
}

export default LanguageTag
